import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FormWrapper, TextForm, Title, Input, SubmitButton, DropDown, Disclaimer } from './styled'
import { FormControl, InputLabel, MenuItem, NoSsr, Select } from '@material-ui/core'
import createUser from '../utils/exodus'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

let schema = Yup.object().shape({
  phoneNumber: Yup.string()
    .length(10, 'Phone number must be at least 10 digits')
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  zipcode: Yup.number()
    .min(5, 'Too Short!')
    .max(5, 'Too Long!')
    .moreThan(9999, 'Need to enter a valid 5 digit ZIP Code')
    .lessThan(100000, 'Need to enter a valid 5 digit ZIP Code')
    .required('Required'),
  radius: Yup.number()
    .required('Required'),
})

const SubscribeForm = () => {
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      zipcode: '',
      radius: 5,
    },
    validationSchema: schema,
    onSubmit: values => {
        createUser(values).then(res => {
          if (res.status === 200) {
            // alert(JSON.stringify(values, null, 2));
          } else {
            alert(res.status)
          }
        })
    },
  })

  return(
    <FormWrapper>
      <TextForm>
        <Title>
          Track Vaccine Availability Near You
        </Title>
        <form onSubmit={formik.handleSubmit}>
          <NoSsr>
            <Input
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              type="tel"
              maxLength="10"
              variant="filled"
              margin="dense"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
            <Input
              fullWidth
              id="zipcode"
              name="zipcode"
              label="ZIP Code"
              type="tel"
              maxLength="5"
              variant="filled"
              margin="dense"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
              helperText={formik.touched.zipcode && formik.errors.zipcode}
            />
          </NoSsr>
          <DropDown variant="filled" fullWidth margin="dense">
            <InputLabel>Radius</InputLabel>
            <Select
              fullWidth
              id="radius"
              name="radius"
              placeholder="Radius"
              value={formik.values.radius}
              onChange={formik.handleChange}
            >
              <MenuItem value={5}>5 miles</MenuItem>
              <MenuItem value={10}>10 miles</MenuItem>
              <MenuItem value={15}>15 miles</MenuItem>
              <MenuItem value={25}>25 miles</MenuItem>
              <MenuItem value={50}>50 miles</MenuItem>
            </Select>
          </DropDown>
          <SubmitButton color="primary" variant="contained" fullWidth type="submit">
            Get Notified
          </SubmitButton>
        </form>
        <Disclaimer>
          By clicking Get Notified, you agree to our <a href="https://support.goodrx.com/hc/en-us/articles/115005225563-Terms-of-Use" target="_blank" rel="noreferrer noopener">Terms</a> and <a href="https://support.goodrx.com/hc/en-us/articles/115005228506-Privacy-Policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and also consent to receive marketing texts from or on behalf of GoodRx, to any number you provide to us, to which messages may be sent by automatic telephone dialing system. Reply STOP to opt out of marketing texts.
        </Disclaimer>
      </TextForm>
    </FormWrapper>
  )
}

export default SubscribeForm