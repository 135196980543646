import styled from 'styled-components'
import { TextField, Button, Select, FormControl } from '@material-ui/core'

export const FormWrapper = styled.div`    
    text-align: left;
    font-family: sans-serif;
`

export const TextForm = styled.div`
    width: 360px;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(70, 85, 134, 0.15);
    border-radius: 16px;
    padding: 20px;
`

export const Title = styled.div`
    font-size: 19px;
    line-height: 121%;
    margin-bottom: 12px;
`

export const Disclaimer = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #606D7A;
    margin-top: 16px;
`

export const Input = styled(TextField)`
    label.Mui-focused {
        color: #606D7A;
        border-color: #e2e8f0;
    }  
    .MuiInputBase-root {
        fieldset {
          background-color: #ffffff;
          border-color: #e2e8f0;
        }
        &:hover fieldset {
          background-color: #ffffff;
          border-color: #e2e8f0;
        }
        &.Mui-focused fieldset {
          background-color: #ffffff;
          border-color: #e2e8f0;
        }
      }
`

export const SubmitButton = styled(Button)`
    .MuiButtonBase-root {
        disableElevation: true,
        fieldset {
            border-radius: 8px;
        }
    }
    .MuiButton-label {
        text-transform: initial;
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 125%;
        padding-top: 14px;
        padding-bottom: 14px;
    }
`

export const DropDown = styled(FormControl)`
    label.Mui-focused {
        color: #606D7A;
        border-color: #e2e8f0;
    }  
    .MuiFormControl-root {
        fieldset {
        background-color: #ffffff;
        border-color: #e2e8f0;
        }
        &:hover fieldset {
        background-color: #ffffff;
        border-color: #e2e8f0;
        }
        &.Mui-focused fieldset {
        background-color: #ffffff;
        border-color: #e2e8f0;
        }
    }
`
