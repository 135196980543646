import React from 'react'
import getUser from '../utils/exodus'

const Profile = (urlParams) => {
    const profileId = urlParams.location.search.slice(1)
    // const data = () => getUser(profileId)

  return(
      <div>
        <h3>Profile</h3>
        <h4>Phone Number:</h4>
        <p>555 867 5309</p>
        <h4>Address:</h4>
        <p>Beverly Hills, CA 90210</p>
        <h4>Distance Willing to Travel:</h4>
        <p>Up to 15 miles</p>
      </div>
  )
}

export default Profile