/* eslint no-restricted-globals: "off" */
import React from 'react'
import { render } from 'react-dom'
import './index.css'
import App from './App'
import { createBrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals'

const history = createBrowserHistory();

let app = document.getElementById('root')
if (app) {
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
	if (path) {
		history.replace(path);
  }

  render(<App />, app);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
