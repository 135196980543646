const post = async (url, user) => {
    const request = {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      }
      return fetch('https://api.prod.projectexodus.us' + url , request)
}

const createUser = async (values) => {  
    const payload = {
      radius: values.radius,
      phoneNumber: values.phoneNumber,
      zipcode: values.zipcode,  
    }

    return post('/create-user', payload)
}

const getUser = async (profileId) => {
  const request = {
    method: 'GET',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    }
  }

  return fetch('https://api.prod.projectexodus.us/get-user/' + profileId, request)
}

// eslint-disable-next-line
export default { createUser, getUser }