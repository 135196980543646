/* eslint no-restricted-globals: "off" */
import SubscribeForm from './components/SubscribeForm'
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom'
import Profile from './components/Profile'
import queryString from 'querystring'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFE06A',
      contrastText: '#000',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  const urlQueryString = location.search
  const params = queryString.parse(urlQueryString.slice(1))
  params.url = location.pathname
  params.hash = location.hash

  return (
    <Router basename="/" hashType="noslash">
      <ThemeProvider theme={theme}>
        <div className="App">
          <Route exact path="/" component={SubscribeForm}></Route>
          <Route path="/profile" component={Profile} urlParams={params}></Route>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
